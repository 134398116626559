<template>
  <!-- Main Start -->
  <section v-if="features1 != ''" class="hot-picks-section">
    <div class="container">
      <h2 class="section-title">Top Intro's</h2>
      <VueSlickCarousel v-bind="settings">
        <div v-for="(item, index) in count">
          <router-link :to="{ path: '/single-company/' + features1[index].company }">
            <div class="Card">
              <img :src="features1[index].image" class="img-fluid" alt="" />
              <div class="Card_info">
                <h6 class="card-title">{{ features1[index].title }}</h6>
                <h6 class="card-info">{{ features1[index].title }}</h6>
              </div>
            </div>
          </router-link>
        </div>

      </VueSlickCarousel>
    </div>
  </section>
  <!-- Main End -->
</template>

<script>

import { db } from '@/main'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: {
    'VueSlickCarousel': VueSlickCarousel
  },
  data() {
    return {
      count: 5,
      features1: [],
      size: '',
      slug: "",
      components: {},
      settings: {
        "responsive": [
          {
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": true,
              "autoplay": true
            }
          },
          {
            "breakpoint": 3000,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": true,
              "autoplay": true
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": true,
              "autoplay": true
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true,
              "autoplay": true
            }
          }
        ]
      }
    }
  },

  created: function () {
    this.fetchFeatures();
  },

  watch: {

    // Watch for route changes
    $route(route) {
      this.features1 = [];
      console.log(route.params.slug);

      this.fetchFeatures();

    },
  },

  methods: {

    fetchFeatures() {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      this.features1 = [];
      this.slug = this.$route.params.slug;
      console.log(this.slug)
      db.collection("countries")
        // .where("slug", "==", this.slug)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((docs) => {
            var countrySlug = docs.data().country.replace(/\s+/g, '-').toLowerCase();
            // console.log(countrySlug == this.slug)
            if (countrySlug == this.slug) {
              db.collection("offers")
                .where("country", "==", docs.id)
                .get()
                .then((querySnapshot) => {

                  querySnapshot.forEach((doc) => {
                    if (doc.data().blogshowoption == 1) {
                      //this.size =  doc.data().size;
                      this.features1.push({
                        id: doc.id,
                        title: doc.data().title,
                        image: doc.data().image,
                        company: doc.data().company,
                        created: doc.data().created,
                      });

                      // this.tags.push(doc.data())
                      this.count = this.features1.length;

                      // console.log("Top Intro =>", this.features1)
                    }
                    // });


                  });
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });
            }
          });
          // console.log("Count =>", this.count)
        });




    }
  }
}

</script>
<style>
.slick-dots li button:before {
  font-size: 15px;
  color: #03e1bc;
}

.slick-dots li.slick-active button:before {
  color: #03e1bc;
}

.hot-picks-section .slick-next::before {
  color: #03e1bc;
}

.hot-picks-section .slick-prev::before {
  color: #03e1bc;
}

.hot-picks-section .slick-prev.slick-disabled:before,
.hot-picks-section .slick-next.slick-disabled:before {
  opacity: 0.5;
}

@media (max-width:1024px) {
  .hot-picks-section .slick-next {
    right: -14px;
  }

  .hot-picks-section .slick-prev {
    left: -14px;
  }
}
</style>
